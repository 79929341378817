import React, { Component } from "react"
import parse from "html-react-parser"
import { withTranslation, Link } from "gatsby-plugin-react-i18next"
import Product from "./Product"
import InformationPanel from "./InformationPanel"
import Carousel from "./Carousel"
import CardInfo from "./CardInfo"
import { parseCurrency, setPathSlash, setPathCanonical } from "./lib/utils"
import facebook from "../../static/facebook-rojo.svg"
import twitter from "../../static/twitter-rojo.svg"
import descriptionIcon from "../../static/icono-descripcion.svg"
import Faq from "./Faq"
import _intersection from "lodash/intersection"
import _isEmpty from "lodash/isEmpty"
import ExperiencesBreadcrumb from "./ExperiencesBreadcrumb"
import Helmet from "react-helmet"
import { onAddToCartDL, onViewItemDL } from "./lib/dataLayer"
import _sortBy from "lodash/sortBy"

/**
 * Mapping para montaje de secciones disponibles.
 *
 * id: indica el nombre del div al que apunta la sección.
 * name: indica el texto traducible del título de sección.
 * order: indica el orden de renderizado.
 * show: indica si la sección debe mostrarse o no.
 */
const sectionMapping = {
  description: {
    id: "descripcion",
    order: 1,
    show: true,
  },
  information: {
    id: "informacion",
    order: 2,
    show: true,
  },
  book: {
    id: "reservar",
    order: 3,
    show: true,
  },
  address: {
    id: "como-llegar",
    order: 4,
    show: true,
  },
  faqs: {
    id: "faq",
    order: 5,
    show: true,
  },
  "full-description": {
    id: "descripcion-completa",
    order: 0,
    show: false,
  },
}

class Experience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bottomButtonsVisible: false,
      topTitleVisible: false,
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      // window.$("body").scrollspy({target: "#submenu"})

      this.toggleBottomElements()
      this.toggleTopElements()

      onViewItemDL(this.props.experience)

      window.$(window).scroll(
        function () {
          this.toggleBottomElements()
          this.toggleTopElements()
        }.bind(this)
      )
    }
  }

  /**
   * Método para mostrar u ocultar botones que solamente aparecen al hacer scroll
   */
  toggleBottomElements() {
    if (
      (window.scrollY > 950 && window.$(window).width() >= 992) ||
      (window.scrollY > 740 && window.$(window).width() < 992)
    ) {
      this.setState({ bottomButtonsVisible: true })
    } else {
      this.setState({ bottomButtonsVisible: false })
    }
  }

  /**
   * Método para mostrar u ocultar títutlo que solamente aparecen al hacer scroll
   */
  toggleTopElements() {
    if (
      (window.scrollY > 230 && window.$(window).width() >= 992) ||
      (window.scrollY > 170 && window.$(window).width() < 992)
    ) {
      this.setState({ topTitleVisible: true })
    } else {
      this.setState({ topTitleVisible: false })
    }
  }

  /**
   * Método para construir un array con las secciones disponibles
   */
  buildExperienceSections(sections) {
    let shownSections = {}
    let hiddenSections = {}

    // Generating existing sections
    sections.forEach(section => {
      const mappedSection = sectionMapping[section.id]
      if (mappedSection) {
        shownSections[section.id] = {
          ...mappedSection,
          name: section.name,
          shortName: section.short_name,
        }
      }
    })

    // Generating hidden sections
    Object.entries(sectionMapping).forEach(([key, section]) => {
      if (
        !Object.values(shownSections).some(
          shownSection => shownSection.id === section.id
        )
      ) {
        hiddenSections[key] = section
      }
    })

    const items = { ...shownSections, ...hiddenSections }
    const links = _sortBy(items, section =>
      !section.order ? Number.MAX_SAFE_INTEGER : section.order
    )

    return { links, items }
  }

  /**Extra Conditions
   * Método para construir un JSON con las secciones del Card-Info
   * @param {*} t
   */
  buildCardInfoSections(t) {
    const colorStyle = ".cls-1{fill: #e73333;}"
    return [
      {
        title: (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.99 13.75">
              <defs>
                <style>{colorStyle}</style>
              </defs>
              <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                  <path
                    className="cls-1"
                    d="M17,11.53V5.73A.76.76,0,0,0,16.23,5H12.09V6.58a1.49,1.49,0,0,1-.09.54h2.46a.41.41,0,0,1,0,.81H11.33a1.65,1.65,0,0,1-.85.24H5.8v3.36a.75.75,0,0,0,.76.76h5.87a.38.38,0,0,1,.33.16l1,1.3,1-1.3a.48.48,0,0,1,.34-.16h1.16a.75.75,0,0,0,.76-.76Zm-2.55-1.3H8.35a.4.4,0,0,1-.4-.4.39.39,0,0,1,.4-.4h6.09a.39.39,0,0,1,.4.4A.4.4,0,0,1,14.44,10.23Z"
                  />
                  <path
                    className="cls-1"
                    d="M11.19,6.58V.76A.75.75,0,0,0,10.43,0H.76A.76.76,0,0,0,0,.76V6.58a.75.75,0,0,0,.76.76H1.93a.37.37,0,0,1,.33.16l1,1.3,1-1.3a.48.48,0,0,1,.34-.16h5.86A.75.75,0,0,0,11.19,6.58ZM2.57,2.15H6.22a.4.4,0,0,1,.4.4.39.39,0,0,1-.4.4H2.57a.39.39,0,0,1-.4-.4A.4.4,0,0,1,2.57,2.15ZM8.64,5.28H2.57a.4.4,0,0,1,0-.8H8.64a.4.4,0,0,1,.4.4A.39.39,0,0,1,8.64,5.28Z"
                  />
                </g>
              </g>
            </svg>
            &nbsp;
            {t("Idiomas")}
          </>
        ),
        body: this.props.experience.languages.map((language, i) => [
          i > 0 && " · ",
          language.toUpperCase(),
        ]),
        highlight: false,
        className: "mb-1 px-4 pt-5",
        button: null,
      },
      {
        title: (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.25 13.25">
              <defs>
                <style>{colorStyle}</style>
              </defs>
              <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                  <path
                    className="cls-1"
                    d="M6.63,0a6.63,6.63,0,1,0,6.62,6.63A6.63,6.63,0,0,0,6.63,0Zm0,12.31a5.68,5.68,0,1,1,5.68-5.68A5.68,5.68,0,0,1,6.63,12.31Z"
                  />
                  <path
                    className="cls-1"
                    d="M6.63,2.37a.48.48,0,0,0-.48.47V6.15H2.84a.48.48,0,0,0-.47.48.47.47,0,0,0,.47.47H6.63a.47.47,0,0,0,.47-.47V2.84A.47.47,0,0,0,6.63,2.37Z"
                  />
                </g>
              </g>
            </svg>
            &nbsp;
            {t("Duración aprox.")}
          </>
        ),
        body: this.props.experience.duration.formatted,
        highlight: false,
        className: "mb-1 pt-2 pb-5",
        button: null,
      },
      // {
      // 	"title": t("Dificultad"),
      // 	"body": this.props.experience.difficulty,
      // 	"highlight": false,
      // 	"button": null
      // },
      {
        title: t("Desde"),
        body: parseCurrency(
          this.props.i18n.language,
          this.props.experience.lowestRate.value,
          this.props.experience.lowestRate.currency
        ),
        highlight: true,
        className: "mb-0 seccion-comprar",
        button: (
          <React.Fragment>
            <Link
              key="main-experience"
              to={setPathSlash(
                `/booking/experience/${this.props.experience.experienceId}`
              )}
              onClick={() => {
                onAddToCartDL(this.props.experience, "boton principal")
              }}
              rel={"nofollow"}
            >
              <button
                className={`mb-2 btn btn-primary btn-block`}
                rel="nofollow"
              >
                <span>{t("Comprar ahora")} &gt;</span>
              </button>
            </Link>
          </React.Fragment>
        ),
      },
    ]
  }

  render() {
    const { t } = this.props
    let sections = this.buildCardInfoSections(t)
    const { links, items: experienceSections } = this.buildExperienceSections(
      this.props.experience.sections
    )

    const onClickMenu = menuItem => {
      if (typeof window !== `undefined`) {
        window.addDataLayer({
          event: "menu_secciones_click",
          menu_Item: menuItem,
        })
      }
    }
    const onClickInfoPanel = (experienceName, selectedInfo) => {
      if (typeof window !== `undefined`) {
        window.addDataLayer({
          event: "informacion_experiencias_click",
          experience: experienceName,
          selected_information: selectedInfo,
        })
      }
    }

    function InfoPill({ t, tags, locale }) {
      const validTags = ["pill_novelty", "pill_not_available"]
      const availableTags = _intersection(validTags, tags)

      if (_isEmpty(availableTags)) {
        return null
      } else {
        return (
          <div
            className="info-pill"
            style={{
              backgroundImage:
                `url("/info-pills/in-experience/` +
                availableTags[0] +
                `-` +
                locale +
                `.svg")`,
            }}
            title={t("tags_" + availableTags[0])}
          ></div>
        )
      }
    }

    function StructuredData({ experience, lang }) {
      return (
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Product",
                "name": "` +
              experience.name +
              `",
                "image": [` +
              experience.images.map(item =>
                item.remoteImage.childImageSharp !== null
                  ? '"' +
                  experience.siteBaseUrl +
                  item.remoteImage.childImageSharp.fixed.src +
                  '"'
                  : ""
              ) +
              `],
                "description": "` +
              experience.seo.description +
              `",
                "brand": {
                  "@type": "Brand",
                  "name": "Tamarán"
                },
                "offers": {
                  "@type": "Offer",
                  "url": "` +
              setPathCanonical(experience.canonical_url) +
              `/",
                  "priceCurrency": "` +
              experience.lowestRate.currency +
              `",
                  "price": "` +
              experience.lowestRate.value +
              `"
                }
              }
            `}
          </script>
        </Helmet>
      )
    }
    const language = this.props.i18n.language
    return (
      <React.Fragment>
        <StructuredData experience={this.props.experience} lang={language} />
        <div className={`articulo page-experiencia container-principal`}>
          <ExperiencesBreadcrumb
            experienceId={this.props.experience.experienceId}
          />
          <div className={`row pt-4`}>
            <div
              className={`col-lg-10 col-md-9 col-sm-9 col-xs-12 justify-content-end`}
            >
              <h1
                id="description-title"
                className="font-weight-light text-center text-lg-left mb-hi-custom"
              >
                {this.props.experience.name}
              </h1>
            </div>
            <div
              className={`col-lg-2 col-md-3 col-sm-3 col-xs-12 d-flex align-items-end justify-content-end`}
            >
              <div className="compartir d-flex align-items-center text-dark mb-compatir-custom font-weight-light">
                <span className="mr-3">{t("Compartir")}</span>
                <a
                  className="mr-3"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${typeof window !== "undefined" ? window.location.href : ""
                    }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="Facebook"></img>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${typeof window !== "undefined" ? window.location.href : ""
                    }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter"></img>
                </a>
              </div>
            </div>
          </div>
          <InfoPill t={t} tags={this.props.experience.tags} locale={language} />
          <div className={`row`}>
            <div className="col">
              <div className="border-experiencia">
                <div className="row">
                  <Carousel
                    classes={`col-12 col-lg-9 pr-lg-0`}
                    images={this.props.experience.images}
                  ></Carousel>
                  <CardInfo
                    classes={`col-12 col-lg-3 pl-lg-0`}
                    sections={sections}
                  ></CardInfo>
                </div>
              </div>
              {/* Navbar y elementos sticky */}
              {/* <div className={`row sticky-top pt-2 bg-white`}>
							{this.state.toggleHiddenElements &&
								<div className={`col-12`}>
									<h2>{this.props.experience.name}</h2>
								</div>
							}
						</div> */}
            </div>
          </div>
          <nav className="d-none d-lg-flex row navbar navbar-default section-contextual-menu sticky-top my-5">
            <div className={`container-fluid d-none d-lg-block`}>
              <div
                id="expSubmenu"
                className={`navbar-container navbar-collapse no-padding`}
                aria-expanded="false"
              >
                <ul className={`nav navbar-nav`}>
                  {links &&
                    links.map(section => (
                      <li
                        className={`nav-item ${section.show || "d-none"}`}
                        key={section.id}
                      >
                        <a
                          className={`menu-collapse nav-link ${section.order === 1 && "active"
                            }`}
                          href={`#${section.id}`}
                          onClick={() => onClickMenu(section.id)}
                        >
                          {t(section.shortName)}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </nav>
          <hr
            id="experience-page-content-start"
            className="mb-0 mt-5 mb-sm-3"
          ></hr>
          <div className="spy-container">
            {this.props.experience.description_summary && (
              <div
                id={experienceSections["description"].id}
                className={`anchor-section`}
                style={{ paddingTop: 40, paddingBottom: 70 }}
              >
                <div className={`text-justify cuerpo-seccion`}>
                  {(() => {
                    try {
                      const description = parse(
                        this.props.experience.description_summary,
                        {}
                      )
                      return <p className="px-2">{description}</p>
                    } catch {
                      console.error(
                        "Error al parsear la descripción en la experiencia" +
                        this.props.experience.name
                      )
                    }
                  })()}
                </div>
                {this.props.experience.description?.description && (
                  <div className={`pt-5 text-center`}>
                    <a
                      id="description-full"
                      className="button-red btn btn-primary p-2 pt-3 px-5"
                      href={`#${experienceSections["full-description"].id}`}
                      onClick={() => onClickMenu("descripcion-completa")}
                    >
                      <b>{t("DescripciónCompleta")}</b>
                    </a>
                  </div>
                )}
              </div>
            )}
            {this.props.experience.description?.summaryBlock && (
              <div
                id={experienceSections["description"].id}
                className={`anchor-section`}
                style={{ paddingTop: 5, paddingBottom: 70 }}
              >
                <div className={`destacado`}>
                  <div className={`row`}>
                    <div className={`col-12 col-sm-6`}>
                      <span>
                        {(() => {
                          try {
                            return parse(
                              this.props.experience.description.summaryBlock[0]
                                .title,
                              {}
                            )
                          } catch {
                            console.error("Error al parsear")
                          }
                        })()}
                      </span>
                      <div>
                        {(() => {
                          try {
                            return parse(
                              this.props.experience.description.summaryBlock[0]
                                .content,
                              {}
                            )
                          } catch {
                            console.error("Error al parsear")
                          }
                        })()}
                      </div>
                    </div>
                    <div className={`col-12 col-sm-6`}>
                      <span>
                        {(() => {
                          try {
                            return parse(
                              this.props.experience.description.summaryBlock[1]
                                .title,
                              {}
                            )
                          } catch {
                            console.error("Error al parsear")
                          }
                        })()}
                      </span>
                      <div className="incluye">
                        {(() => {
                          try {
                            return parse(
                              this.props.experience.description.summaryBlock[1]
                                .content,
                              {}
                            )
                          } catch {
                            console.error("Error al parsear")
                          }
                        })()}
                      </div>
                      {this.props.experience.description.summaryBlock[2] && (
                        <>
                          <br />
                          <span>
                            {(() => {
                              try {
                                return parse(
                                  this.props.experience.description
                                    .summaryBlock[2].title,
                                  {}
                                )
                              } catch {
                                console.error("Error al parsear")
                              }
                            })()}
                          </span>
                          <div className="no-incluye">
                            {parse(
                              this.props.experience.description.summaryBlock[2]
                                .content,
                              {}
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              id={experienceSections["information"].id}
              className={`anchor-section`}
              style={{ paddingTop: 5, paddingBottom: 70 }}
            >
              <div className={`pb-4 text-center`}>
                <h2>{experienceSections["information"].name}</h2>
              </div>
              {this.props.experience.information.map((item, i) => {
                return (
                  <InformationPanel
                    key={i}
                    title={item.title}
                    className={item.class}
                    body={item.body}
                    id={`${experienceSections["information"].id}_` + i}
                    isOpen={i === 0}
                    onClick={() =>
                      onClickInfoPanel(
                        `${this.props.experience.experienceId}-${this.props.experience.name}`,
                        item.title
                      )
                    }
                  />
                )
              })}
            </div>
            <div
              id={experienceSections["book"].id}
              className={`anchor-section`}
              style={{ paddingTop: 5, paddingBottom: 70 }}
            >
              <div className={`pb-4 text-center`}>
                <h2>{experienceSections["book"].name}</h2>
              </div>
              {this.props.experience.products.map((product, i) => {
                return (
                  <Product
                    key={i}
                    product={product}
                    id={`product_` + i}
                    experienceId={this.props.experience.experienceId}
                    experience={this.props.experience}
                  />
                )
              })}
            </div>
            <div
              id={experienceSections["address"].id}
              className={`anchor-section`}
              style={{ paddingTop: 5, paddingBottom: 70 }}
            >
              <div
                className={`pb-4 text-center`}
                style={{ position: "relative" }}
              >
                <h2>{experienceSections["address"].name}</h2>
              </div>
              <InformationPanel
                id={`${experienceSections["full-description"].id}_1`}
                title={t("Localización")}
                showMap={true}
                coordinates={this.props.experience.coordinates}
                onClick={() =>
                  onClickInfoPanel(
                    `${this.props.experience.experienceId}-${this.props.experience.name}`,
                    "Localización"
                  )
                }
              />
            </div>
            <div
              id={experienceSections["faqs"].id}
              className={`anchor-section`}
              style={{ paddingTop: 5, paddingBottom: 70 }}
            >
              {this.props.faq && this.props.faq.length > 0 && (
                <>
                  <div className={`pb-4 text-center`}>
                    <h2>{experienceSections["faqs"].name}</h2>
                  </div>
                  <Faq id={this.props.faq} mini={true} />
                </>
              )}
            </div>
            {this.props.experience.description?.description && (
              <div
                id={experienceSections["full-description"].id}
                className={`anchor-section`}
                style={{ paddingTop: 5, paddingBottom: 70 }}
              >
                <div
                  className={`pb-4 text-center`}
                  style={{ position: "relative" }}
                >
                  <h2>{experienceSections["description"].name}</h2>
                </div>
                <InformationPanel
                  id={`${experienceSections["full-description"].id}_1`}
                  title={experienceSections["description"].shortName}
                  customIcon={descriptionIcon}
                  body={this.props.experience.description?.description}
                  isOpen={true}
                  onClick={() =>
                    onClickInfoPanel(
                      `${this.props.experience.experienceId}-${this.props.experience.name}`,
                      experienceSections["description"].shortName
                    )
                  }
                />
              </div>
            )}
          </div>
          {/* Botones sticky-bottom para la vista móvil */}
          <div className="row sticky-bottom pb-2 bg-white d-lg-none pt-2">
            {this.state.bottomButtonsVisible && (
              <>
                <div className={`col-6 col-md-4`}>
                  <a href={`#${experienceSections["information"].id}`}>
                    <button className={`btn btn-primary btn-block`}>
                      <span style={{ fontSize: "1.25rem" }}>
                        {experienceSections["information"].shortName}
                      </span>
                    </button>
                  </a>
                </div>
                <div className="d-none d-md-block col-md-4" />
                <div className={`col-6 col-md-4`}>
                  <Link
                    to={setPathSlash(
                      `/booking/experience/${this.props.experience.experienceId}`
                    )}
                    onClick={() => {
                      onAddToCartDL(this.props.experience, "boton scroll")
                    }}
                    rel={"nofollow"}
                  >
                    <button
                      className={`btn btn-primary btn-block`}
                      rel="nofollow"
                    >
                      <span style={{ fontSize: "1.25rem" }}>
                        {t("Comprar ahora")}
                      </span>
                    </button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation("Experience")(Experience)
